@import 'variables';
@import 'mixins';

/**
 * Layout
 */
.page-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 120px;

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $content-max-width;
    @include page-side-padding;
  }

  @media #{$sm-devices} {
    padding-bottom: 60px;
  }
}

.dashboard-page-content {
  display: flex;
  gap: 30px;

  >* {
    flex: 1 1 0%;
  }
}

/**
 * Misc
 */
.text-error {
  color: $color-error;
}
.text-accent {
  color: $color-accent;
}

.bolded-text {
  font-weight: $fw-bold;
}

.width-100 {
  width: 100%;
}


/**
 * Forms
 */
.form-element-width {
  width: 100%;
  max-width: $form-element-max-width;
}

.form-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 22px;
  width: 100%;
  max-width: $form-element-max-width;

  @media #{$sm-devices} {
    flex-direction: column;
    justify-content: flex-start;

    >* {
      width: 100%;
    }

    &.row {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &.row {
    @extend .flex-row;
  }
}

/**
 * Show / Hide
 */
.hide-md {
  @media #{$md-devices} {
    display: none !important;
  }
}
.hide-gt-md {
  @media #{$gt-md-devices} {
    display: none !important;
  }
}
