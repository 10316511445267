@import 'src/styles/utils';

.transactions-table {
  .t-body {
    .transaction-row {
      .t-row {
        padding: 10px 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-separator;
      }
    }
  }

  .t-row .t-cell {
    text-align: left;
    flex: 0 1 18%;

    &.cell-invoice {
      flex: 0 1 10%;
      text-align: center;

      .app-icon {
        font-size: 20px;
      }
    }

    &.cell-value {
      text-align: right;
      padding-right: 20px;
    }
  }

  @media #{$sm-devices} {
    .t-row .t-cell {
      &.cell-user {
        flex: 0 1 10%;
      }

      &.cell-value, &.cell-date, &.cell-transaction-no {
        flex: 0 1 30%;
      }
    }

    .more-details {
      .app-icon-download {
        font-size: 24px;
      }
      .reference {
        display: none;
      }
    }
  }

  @media #{$xs-devices} {
    .t-row .t-cell {
      &.cell-user {
        flex: 0 1 20%;
      }

      &.cell-value, &.cell-date {
        flex: 0 1 40%;
      }
    }

    .more-details {
      .reference {
        display: block;
      }
    }
  }
}