@import 'src/styles/utils';

.dashboard-section-container {
  border-radius: 20px;
  border: 1px solid $color-separator-secondary;
  padding: 30px;
  min-width: 450px;
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}