@import 'src/styles/utils';

.register-page-step-selfie {
  width: 100%;

  .document-container {
    display: flex;

    &.selfie-document-container {
      height: 200px;
    }
    &.id-card-document-container {
      height: 120px;
    }

    .side-column {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 0%;

      .app-icon-check-circle {
        font-size: 32px;
        color: $color-success;
      }
    }

    .center-column {
      width: 270px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: auto;
        opacity: .25
      }
    }
  }

  .form-buttons-container {
    margin-top: 40px;
  }
}
