@import 'src/styles/utils';


.assistance-page-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    padding-top: 60px;
    gap: 30px;

    .topic-menu {
        width: 360px;
        padding: 30px 12px 45px 45px;
        border-right: 1px solid $color-separator-secondary;
        box-sizing: border-box;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        min-height: 500px;
        color: #000000;

        .topic-item {
            color: $color-text-dark;
            display: flex;
            align-items: center;
            cursor: pointer;

            h4 {
                padding-right: 36px;
                width: 210px;
            }

            .app-icon-arrow-right {
                font-size: 19px;
                color: $color-accent;
                display: none;
            }

            &.active {
                h4 {
                    color: $color-accent;
                }

                .app-icon-arrow-right {
                    display: flex;
                }
            }
        }
    }

    .topic-content {
        padding: 60px 60px;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        gap: 30px;

        .content-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .content-title {
                cursor: pointer;
                margin: 0px;

                span {
                    font-size: 20px;
                    color: #F15D60;
                }
            }

            .content-text-container {
                padding: 10px 0 40px 20px;
                border-bottom: 1px solid $color-separator-secondary;
                width: 840px;
                margin-bottom: 10px;

                .content-text {
                    color: #717171;
                    letter-spacing: 0.2px;
                }
            }
        }
    }
}