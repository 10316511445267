@import 'src/styles/utils';

.custom-form-radio-group {
    .radio-group-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .separator-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%; 
            margin: 10px 0;
            position: relative;

            .separator-line {
                flex-grow: 1;
                height: 1px;
                background-color: #F4F4F4; 
            }

            .separator-text {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 40px;
                background-color: #F4F4F4; 
                color: #717171;
                font-weight: $fw-normal;
                border-radius: 30px;
            }
        }

        .radio-text {
            margin: 0px 0px 10px 58px;
            color: #717171;
            font-weight: $fw-semi-bold;
            @media #{$lt-1080-devices} {
                font-size: 20px;
                margin: 0px 0px 0px 50px;
            }
        }

        .radio-option-container {
            display: flex;
            align-items: center;
            gap: 19px;
            cursor: pointer;

            

            .radio-container {
                position: relative;
                min-width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border: 1px solid $color-accent;
                border-radius: 50%;
                margin-right: 10px;
                box-sizing: border-box;

                .radio-checked {
                    display: none;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $color-accent;
                }


                &.checked {
                    border: 1px solid $color-accent;

                    .radio-checked {
                        display: flex;
                    }
                }
            }


            .radio-content {

                width: 100%;
                color: #717171;
                font-weight: $fw-normal;

                
                .app-dropdown-container {

                    .dropdown-header {
                        padding-left: 25px;
                        color: #979797;
                        font-weight: $fw-normal;
                    }
                }

                .app-input {
                    input {
                        font-weight: $fw-normal;
                    }
                }

                .app-input,
                .dropdown {
                    width: 100%;
                }
            }
        }
    }

    .form-element-error {
        font-size: 12px;
        color: #F15D60;
        margin-top: 5px;
    }
   
}