.form-element-container {
    margin-bottom: 20px;
  }
  
  .form-label-container {
    margin-bottom: 8px;
  }
  
  .form-label-text {
    color: #717171;
    font-weight: bold;
  }
  
  .error-text {
    color: #F44336;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .input-group {
    display: flex;
    gap: 20px;
  }
  
  .form-input-container {
    background-color: #F4F4F4;
    border-radius: 30px;
    padding: 16px;
    max-width: 130px;
    flex: 1;
  }
  
  .form-input {
    width: 100%;
    border: none;
    background: transparent;

    :focus {
        border: none;
    }
    :hover {
        border: none;
    }
  }
  
  .gps-location-icon-container {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #F15D60;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
  
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  
  .gps-location-icon {
    width: 24px;
    height: 24px;
  }
  
  .form-element-error-text {
    color: #F44336;
    font-size: 13px;
    line-height: 15px;
    margin-top: 8px;
  }
  