@import 'src/styles/utils';

.app-layout {
  nav.topnav {
    .section-heading {
      height: 100px;

      .logo {
        flex: 1 1 0%;

        img {
          cursor: pointer;
          width: 214px;
        }

        .menu-trigger {
          display: none;
        }
      }

      .search-bar {
        display: flex;
        align-items: center;
        gap: 7px;
        padding: 0 14px 0 19px;
        box-sizing: border-box;
        width: 100%;
        max-width: 570px;
        height: 50px;
        border: 1px solid $color-accent;
        border-radius: 25px;
        margin: 0 20px;

        .app-icon-search {
          color: $color-accent;
        }
        .app-icon-close {
          color: $color-text-dark;
          cursor: pointer;
        }
        .search-placeholder {
          color: #A0A0A0;
        }
      }

      .user-menu {
        gap: 30px;

        .user-menu-item {
          cursor: pointer;

          .app-icon {
            font-size: 22px;
          }
        }

        @media #{$lt-500-devices} {
          gap: 20px;
        }
      }

      @media #{$sm-devices} {
        height: 80px;

        .logo {
          flex: unset;
          gap: 18px;

          img {
            width: 109px;
            padding-bottom: 4px;
          }

          .menu-trigger {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .app-icon {
              font-size: 24px;
            }
          }
        }

        .search-bar {
          display: none;
        }
      }
    }

    .section-menu {
      height: 50px;
      background: $color-accent;
      color: $color-text-light;
      font-size: 16px;

      .main-menu {
        display: flex;
        gap: 10px;

        a {
          text-decoration: none;
          color: $color-text-light;
        }
      }

      .social-menu {
        .app-icon {
          cursor: pointer;
          font-size: 15px;
          color: $color-text-light;
        }
      }

      @media #{$sm-devices} {
        .main-menu {
          display: none;
        }

        .social-menu {
          .app-icon {
            font-size: 22px;
          }
        }
      }
    }
  }

  .section-payment-options {
    height: 100px;
    background: $color-accent;
    color: $color-text-light;
  }

  footer {
    $section-padding: 55px;

    background: $color-bg-dark;
    color: $color-text-light;

    .section-footer {
      padding: $section-padding 0;

      a {
        text-decoration: none;
        color: $color-text-light;
      }

      .logo img {
        width: 208px;
        color: #F15F61;
        // background-color: #F15F61;
      }

      .header {
        margin: 10px 0px;
        font-size: 24px;
        font-weight: $fw-bold;
        color: #F15F61;

      }
      .text-join {
        color: $color-accent;

      }
      .text-download {
        color: $color-accent;
        font-weight: $fw-bold;
      }
    }
    
    .group-partners {
      display: flex;
      justify-content: center;
      width: 570px;
      height: 40px;
    }

    .section-copyright {
      height: $section-padding;
      font-size: 16px;
      color: #383D38;

      p {
        text-align: center;
        padding-bottom: 3px;
      }
    }
  }

  .page-wrapper {
    width: 100%;
  }

}