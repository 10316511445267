@import 'src/styles/utils';

.app-form-section-title {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 100%;
  max-width: $form-element-max-width;

  .title {
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 24px;
      color: $color-text-disabled;
      font-weight: $fw-bold;
    }

    .text-required {
      font-size: 16px;
      font-weight: $fw-normal;
      color: $color-text-disabled;
      padding-top: 7px;
    }

    .text-optional {
      font-size: 24px;
      color: #A2A2A2;
    }
  }

  p.info {
    font-size: 16px;
    color: $color-text-disabled-secondary;
    font-weight: $fw-semi-bold;
    margin-top: 12px;
  }

  @media #{$sm-devices} {
    padding-top: 0;

    .title {
      h2 {
        color: $color-text-dark;
      }

      .text-required {
        font-size: 16px;
        color: $color-text-dark;
      }

      .text-optional {
        font-size: 24px;
        color: #A2A2A2;
      }
    }

    p.info {
      display: none;
    }
  }
}