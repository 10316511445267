@import 'src/styles/utils';

.users-table {
  .t-row .t-cell {
    text-align: left;

    &.cell-username {
      flex: 0 1 15%;
    }

    &.cell-contact {
      flex: 0 1 35%;
    }

    &.cell-date-added {
      flex: 0 1 15%;
    }

    &.cell-assigned-shop {
      flex: 0 1 20%;
    }

    &.cell-actions {
      flex: 0 1 15%;
    }
  }

  /* TABLET/MOBILE STYLE */
  @media #{$sm-devices} {
    .t-row .t-cell {
      &.cell-contact {
        flex: 0 1 50%;
      }

      &.cell-username, &.cell-actions {
        flex: 0 1 25%;
      }
    }

    .more-details {
      .contact {
        display: none;
      }
    }
  }

  @media #{$xs-devices} {
    .t-row .t-cell {
      &.cell-username, &.cell-actions {
        flex: 0 1 50%;
      }
    }

    .more-details {
      .contact {
        display: block;
      }
    }
  }
}