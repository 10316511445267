@import 'src/styles/utils';

.app-form-date-picker {
  position: relative;

  .date-picker-field-container {
    .form-field-container {
      flex: 1 1 0%;
    }
  }

  .react-date-picker {
    position: absolute;
    width: 100%;

    .react-date-picker__wrapper {
      border: none;

      button.react-date-picker__clear-button,
      button.react-date-picker__calendar-button {
        display: none;
      }

      .react-date-picker__inputGroup {
        >:not(.react-date-picker__inputGroup__year) {
          display: none !important;
        }

        input.react-date-picker__inputGroup__year {
          width: calc(100% + 42px) !important;
          margin-left: -21px;
          height: 50px !important;
          opacity: 0;
          cursor: default;
          border-radius: 30px;
        }
      }
    }
  }
}