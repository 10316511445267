@import 'src/styles/utils';

.centered-section {
  width: 100%;

  >* {
    width: 100%;
    flex: 1 1 0%;
    max-width: $content-max-width;
    @include page-side-padding;
    box-sizing: border-box;
  }
}