@import 'src/styles/utils';

.home-page-part-custom-card-steps {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 40px;

  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 1 0%;

    img {
      width: 90px;
      height: 90px;
    }

    .title-text {
      display: flex;
      flex-direction: column;
      color: $color-text-dark;
      gap: 10px;

      .title {
        font-size: 24px;
        font-weight: $fw-bold;
        white-space: pre-wrap;
      }
      .text {
        font-size: 16px;
        font-weight: $fw-normal;
        white-space: pre-wrap;
      }
    }
  }

  .separator {
    width: 5px;
    background-color: $color-accent;
  }

  @media #{$lt-1080-devices} {
    flex-direction: column;
    align-items: center;

    .step {
      flex: unset;
      flex-direction: column;
      width: 400px;
      max-width: 70%;

      .title-text {
        align-items: center;

        .title {
          text-align: center;
        }
        .text {
          text-align: center;
        }
      }
    }

    .separator {
      width: 40%;
      height: 1px;
    }
  }
}