@import 'src/styles/utils';

.my-designs-page {

    .action-button {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 600;
        color: #F15D60;
        cursor: pointer;
        .app-icon {
        color: #F15D60;
        font-size: 18px;
    }
    }

    .subtitle {
        font-size: 29px;
        color: #F15D60;
    }

    .my-designs-page-content {
    border-radius: 20px;
    border: 1px solid #E4E4E4;
    padding: 30px;
    box-sizing: border-box;
    }

    .gift-card {
        min-width: 270px;

        .background-img-container {
            align-items: normal;
        }
    }
}