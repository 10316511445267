@import 'src/styles/utils';

.merchant-menu {
  width: 360px;
  padding: 0px 12px 45px 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .menu-user {
    display: flex;
    gap: 20px;
    align-items: center;
    border-bottom: 1px solid $color-separator;
    padding-bottom: 17px;
    margin-bottom: 20px;

    .profile-image {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $color-bg-neutral;
    }
    .merchant-name {
      font-size: 24px;
      font-weight: $fw-bold;
      color: $color-text-dark;
    }
    .verified {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      border-radius: 24px;
      background-color: $color-bg-dark;
      color: $color-text-light;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .menu-items-container {
    .menu-item {
      padding: 20px 10px 20px 5px;
      color: $color-text-dark;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid $color-separator;
      }

      .menu-item-icon {
        color: $color-text-dark;
        font-size: 20px;
      }

      .label {
        flex: 1 1 0%;
        padding-left: 36px;
        font-size: 16px;
        font-weight: $fw-bold;
      }

      .app-icon-arrow-right {
        font-size: 19px;
        color: $color-accent;
        display: none;
      }

      &.active {
        .label {
          color: $color-accent;
        }

        .app-icon-arrow-right {
          display: flex;
        }
      }
    }
  }

  /* TABLET/MOBILE STYLE */
  @media #{$lt-1080-devices} {
    width: 100%;
    padding: 0 15px 30px;

    .menu-user {
      width: 100%;
    }

    .menu-items-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-separator;

      .menu-item {
        border: none !important;

        .label {
          padding-left: 20px;
        }

        .app-icon-arrow-right {
          display: none !important;
        }
      }
    }
  }

  @media #{$xs-devices} {
    .menu-items-container {
      .menu-item {
        .label {
          display: none;
        }

        .menu-item-icon {
          font-size: 32px;
        }

        &.active .menu-item-icon {
          color: $color-accent;
        }
      }
    }
  }
}