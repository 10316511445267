@import 'src/styles/utils';

.my-account-menu {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .menu-user {
    display: flex;
    gap: 20px;
    align-items: center;
    border-bottom: 1px solid $color-separator;
    padding-bottom: 17px;
    margin-bottom: 20px;

    .profile-image {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $color-bg-neutral;
    }
    .user-name {
      font-size: 24px;
      font-weight: $fw-bold;
      color: $color-text-dark;
    }
    .verified {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      border-radius: 24px;
      background-color: $color-bg-dark;
      color: $color-text-light;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  }

  .menu-item {
    padding: 20px 10px 20px 5px;
    color: $color-text-dark;
    display: flex;
    align-items: center;
    cursor: pointer;

    .menu-item-icon {
      color: $color-text-dark;
      font-size: 20px;
    }
    .label {
      flex: 1 1 0%;
      padding-left: 36px;
      font-size: 16px;
      font-weight: $fw-bold;
    }
    .app-icon-arrow-right {
      font-size: 19px;
      color: $color-accent;
      display: none;
    }

    &.active {
      .label {
        color: $color-accent;
      }

      .app-icon-arrow-right {
        display: flex;
      }
    }
   
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: $color-separator-secondary;
  }

  button {
    margin-top: 20px;
    border-radius: 30px; 
  }
}