@import 'src/styles/utils';



.custom-design-item {
    .app-form-section-title {
        padding-top: 20px;

        .title {
            h2 {
                font-size: 16px;
            }
        }

        @media #{$lt-1080-devices} {
            width: 100%;
            align-items: flex-start;
            flex-direction: column;
            padding-bottom: 0px;

            .title {
                color: #717171;

                h2 {
                    font-size: 20px;
                    color: #717171;
                }

                .text-required {
                    font-size: 20px;

                    color: #717171;
                }
            }

            .info {
                display: block;
            }
        }
    }


    &.section1 {
        max-width: 570px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .button {
            width: 210px;
        }

        .custom-form-radio-group {
            width: 100%;
            max-width: none;
        }

    }


    &.section2 {

        .responsize-text {
            @media #{$lt-1080-devices} {
                font-size: 20px;
            }
        }

        .helper-text {
            color: #979797;
            font-size: 12px;
            font-weight: $fw-semi-bold;
        }


        .custom-design-step-card-value {
            flex-direction: row;
        }


        .card-value-quantity-container {
            @media #{$lt-1080-devices} {
                gap: 15px;
                flex-direction: column;
                flex: 1 1 100%;

                .card-value-container {
                    max-width: 100%;
                    justify-content: center;

                    .helper-text {
                        align-self: center;
                    }
                }

                .card-quantity-container {
                    max-width: 100%;
                    align-items: center;

                    .counter-input-button {
                        font-size: 32px;
                    }

                    .counter-input-container {
                        width: 110px;
                        height: 50px;
                        border-radius: 40px;

                    }
                }

            }
        }

        .gift-message-container {
            width: 50%;

            @media #{$lt-1080-devices} {
                width: 100%;
                gap: 30px;

                .app-form-radio-group {

                    .radio-group-container {
                        flex-direction: column;
                    }
                }
            }
        }


        .app-form-textarea {
            @media #{$lt-1080-devices} {
                .app-form-element-label {
                    font-size: 20px;
                }
            }
        }

        .email-inputs-container {
            @media #{$lt-1080-devices} {
                flex-direction: column;

                .app-form-input {
                    max-width: none;

                    .app-form-element-label {
                        font-size: 20px;
                        padding-bottom: 0;

                        label {
                            font-weight: $fw-bold;

                        }
                    }
                }
            }
        }


        .app-form-radio-group {
            .radio-group-container {
                display: flex;
                flex-direction: row;
                gap: 50px;


                .radio-option-container {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    cursor: pointer;

                    .radio-container {
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        border: 1px solid $color-accent;

                        .radio-checked {
                            display: none;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background-color: $color-accent;
                        }

                        &.checked {
                            .radio-checked {
                                display: block;
                            }

                            +.radio-text-container .radio-text {
                                color: $color-bg-dark !important;
                            }
                        }
                    }

                    .radio-text-container {
                        .radio-text {
                            color: #717171 !important;

                        }

                        .radio-secondary-text {
                            color: $color-text-placeholder;
                            font-weight: $fw-normal;
                        }
                    }
                }
            }


        }

        .app-form-element-container {
            &.app-form-input {
                label {
                    font-weight: $fw-normal;
                }
            }
        }
        .buttons-container {
            @media #{$lt-1080-devices} {
                flex-direction: column;
                align-items: center;
                button {
                    width: 100%;
                }
            }
        }
    }  
}