@import 'src/styles/utils';

.generic-table {
  padding: 30px;

  .t-header {
    .t-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 15px;
      border-bottom: 1px solid $color-separator;

      .t-cell {
        font-size: 16px;
        font-weight: $fw-bold;
      }
    }
  }

  .t-body {
    .t-row {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cell-actions {
        text-align: right;
        color: $color-accent;

        span {
          cursor: pointer;

          &.app-icon {
            margin-left: 5px;
            font-size: 18px;
          }
        }
      }
    }
  }

  @media #{$md-devices} {
    .t-row {
      .t-cell {
        &.hide-md {
          display: none !important;
        }
      }
    }
  }

  @media #{$sm-devices} {
    .t-row {
      .t-cell {
        &.hide-sm {
          display: none !important;
        }
      }
    }
  }

  @media #{$xs-devices} {
    .t-row {
      .t-cell {
        &.hide-xs {
          display: none !important;
        }
      }
    }
  }

}