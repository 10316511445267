@import 'src/styles/utils';

.app-form-counter-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  gap: 10px;
  .counter-input-label {
    font-weight: $fw-bold;
  }

  .counter-input-controls {
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #F4F4F4;
    padding: 4px;
    background-color: #fff;

    .counter-input-button {
      color: #717171;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-weight: $fw-bold;
      font-size: 24px;
      transition: visibility 0.2s, opacity 0.2s;

      &:focus {
        visibility: hidden;
        opacity: 0;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:disabled {
        cursor: not-allowed;
        transform: none;
      }
    }

    .counter-input-container {
      width: 90px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 30px;
      padding: 8px 0px;
      margin: 0 8px;
      background-color: #F4F4F4;
      box-sizing: border-box;
      input {
        font-size: 24px;
        text-align: center;
        font-weight: $fw-bold;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
}