@import 'src/styles/utils'; 

.catalogue-page {
    padding: 30px 50px;
    
    .page-outer {

        .page-content {

            .gift-card-purchase {
                margin: 20px 0;
                gap: 10px;

                .gift-card {

                    .card-text {
                      font-size: 13px
                    }
                  }

                .card-purchase-name {
                    font-size: 16px;
                    color: #000000;
                }
            }
        }
    }

    .info-text {
        color: #000000;
        font-weight: $fw-semi-bold;
    }

    
}