@import '../../styles/utils';

.shops-table .t-body .shop-row {
  display: block;

  .t-row {
    .t-cell {
      &.cell-name {
        flex: 0 1 30%;

        .shop-img {
          width: 100px;
        }

        .shop-name {
          font-weight: 600;
        }
      }

      &.cell-coordinates, &.cell-date, &.cell-manager {
        flex: 0 1 20%;
      }

      &.cell-actions {
        flex: 0 1 10%;
        text-align: left
      }
    }
  }

  .address-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background: $color-bg-disabled;
    padding: 10px;
    gap: 10px;

    .address-label {
      font-weight: $fw-bold;
    }

    .address-details {
      .key {
        color: $color-text-disabled;
      }
    }
  }

  @media #{$xs-devices} {
    .t-row .t-cell {
      &.cell-name {
        flex: 0 1 80%;
      }

      &.cell-actions {
        flex: 0 1 20%;
      }
    }
  }
}
