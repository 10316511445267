@import 'src/styles/utils';

.merchant-templates {
  padding: 30px 0;
  border-radius: 20px;
  border: 1px solid $color-separator-secondary;

  .title-container {
    padding: 0 30px;

    .title {
      color: $color-accent;
      font-size: 24px;
      font-weight: $fw-semi-bold-plus;

    }

    .go-back-btn {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: $color-accent;
      &:hover {
        font-weight: 700;
      }
    }
  }


  .subtitle {
    font-size: 20px;
    font-weight: 700;
    padding: 0 30px;
  }
}