@import 'src/styles/utils';

.checkout-page-content-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    padding-top: 60px;
    gap: 30px;

    .app-form-section-title {
        padding: 0px;
        padding-bottom: 20px;
    }

    @media #{$lt-1080-devices} {
        flex-direction: column;
    }

    .details-container {
        min-width: 360px;
        padding: 0px 12px 45px 45px;
        border-right: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        min-height: 500px;
        color: #000000;
        @media #{$lt-1080-devices} {
            order: 2;
            padding: 0px 10px 45px 10px;
            border: none;
        }

    }

    .items-container {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        gap: 30px;

        .app-form-section-title {
            padding: 0px;
        }

        .line-separator {
            max-width: 834px;
            background-color: #979797;
        }

        .item-container {
            display: flex;
            gap: 30px;

            .quantity-container {
                min-width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .title-value-container {
                justify-content: center;
                align-items: flex-start;
                font-weight: $fw-semi-bold;
                width: 250px;

                @media #{$lt-1080-devices} {
                    width: 200px;
                }

                .value {
                    font-weight: $fw-bold;
                    font-size: 24px;
                }
            }

            .buttons-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 30px;
            }

            .card-container {
                max-width: 150px;

                @media #{$lt-1080-devices} {
                    max-width: 260px;
                }

                .gift-card {
                    max-width: 150px;
                    min-width: 150px;
                    border-radius: 6px;

                    @media #{$lt-1080-devices} {
                        max-width: 260px;
                        min-width: 260px;

                    }



                    .background-img-container {
                        align-items: normal;
                    }

                    .card-text {
                        font-size: 6px;
                    }

                    .expiration {
                        font-size: 4px;
                    }

                    .card-number {
                        font-size: 4px;
                    }
                }
            }

            .from-to {
                color: $color-text-placeholder;

                .name-full {
                    font-weight: $fw-bold;
                }
            }

            .app-form-counter-input {
                @media #{$lt-1080-devices} {
                    width: 100%;
                }

                .counter-input-controls {
                    width: 100%;
                    justify-content: space-between;
                }
            }
        }

        .order-total-container {
            width: 40%;
            font-size: 24px;


            @media #{$lt-1080-devices} {
                width: 100%;
            }


            .total {
                font-weight: $fw-bold;
            }

            .width-45 {
                width: 45%;
            }
        }

        .promocode-container {
            height: 60px;
            box-sizing: border-box;
            padding: 3px 4px 3px 15px;
            border-radius: 30px;
            background-color: #F5F5F5;
            width: 400px;

            @media #{$lt-1080-devices} {
                width: 100%;
            }

            .app-icon {
                font-size: 24px;
            }
        }



        .buttons-container {

            @media #{$lt-1080-devices} {
                flex-direction: column;
                gap: 20px;

                .button {
                    width: 100%;
                }

                .continue-shopping {
                    order: 2;
                }

                .payment-buttons-container {
                    flex-direction: column;
                }
            }

            .button-icon {
                overflow: hidden;
                font-size: 16px;
                gap: 10px;
                width: auto;
                font-weight: $fw-bold;
                min-width: 150px;
                border-radius: 25px;
                height: 50px;
                padding: 0 22px;

                @media #{$lt-1080-devices} {
                    width: 100%;
                }
            }
        }
    }
}