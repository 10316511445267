@import 'src/styles/utils';

.pagination-wrapper {
  .container {
    &.disabled {
      opacity: 0.5;
    }
  }
  .page-nav {
    .nav-arrow {
      border: 2px solid $color-accent;
      border-radius: 50%;
      cursor: pointer;

      .app-icon {
        font-size: 16px;
        padding: 5px;
        color: $color-accent;
      }
    }

    .page-number {
      padding: 8px 5px;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;

      &.selected-page {
        color: $color-text-light;
        background-color: $color-accent;
      }
    }
  }
}