@import 'src/styles/utils';

.form-wrapper {
  padding: 30px;
  background: $color-bg-neutral;

  .form-title {
    font-size: 24px;
    font-weight: 700;
    color: $color-accent;
  }

  .cancel-btn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: $color-accent;
    &:hover {
      font-weight: 700;
    }
  }

  .form-content {
    .form-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;

      .half-row-input {
        width: 50%;
        max-width: 50%;
      }
    }

    .app-input.form-field-container {
      background: $color-bg-light;
    }

    .app-dropdown-container {
      .dropdown-header {
        padding-left: 21px;
      }

      .dropdown-list {
        top: 55px;
        .dropdown-option {
          padding: 10px 21px;
        }
      }

      .dropdown-header, .dropdown-list {
        background: $color-bg-light;
        font-size: 16px;
        font-weight: 400;
        color: $color-text-form-field
      }
    }
  }

  .submit-btn {
    width: 100%;
    background: #F6F6F6;

    &:disabled {
      border: 1px solid $color-text-disabled;
      color: $color-text-disabled;
    }
  }

  @media #{$xs-devices} {
    .form-content {
      .form-row {
        flex-direction: column;

        .half-row-input {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}