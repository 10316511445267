@import 'src/styles/utils';

.home-page {
  .card-types-section {
    position: relative;
    padding: 50px 0;

    .card-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      flex: 1 1 0%;

      .card-description-container {
        display: flex;
        align-self: flex-start;
        gap: 20px;

        .separator {
          width: 5px;
          background-color: $color-accent;
        }

        .card-description {
          font-size: 14px;
          font-weight: $fw-semi-bold;
          white-space: pre-wrap;
        }
      }
    }


    .background-image-left {
      position: absolute;
      top: 0;
      left: -100px;
      width: 50%;
      height: 100%;
      z-index: -1;
      overflow: hidden;

      img {
        width: 80%;
        height: 100%;
        object-fit: cover;
        opacity: 10%;
      }
    }

    .background-image-right {
      position: absolute;
      top: 0;
      right: -360px;
      width: 50%;
      height: 100%;
      z-index: -1;
      overflow: hidden;

      img {
        width: 10%;
        height: 100%;
        object-fit: cover;
        opacity: 10%;
        overflow: hidden;
      }
    }
  }

  .shop-by-occasion-section {
    background-color: $color-bg-dark;

    .item-box {
      position: relative;
      width: 205px;
      height: 180px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: $color-bg-light;
      border-radius: 15px;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
        border-radius: 15px;
      }

      .box-layer-container {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 8% 7.2%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        font-weight: 600;
        font-size: 18px;

        span {
          position: absolute;
          font-size: 16px;
          font-weight: $fw-bold;
          color: #FFFFFF;
          z-index: 1;
        }
      }
    }
  }

  .gift-cards-section {
    h2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      font-size: 48px;
      color: $color-text-dark;
      font-weight: $fw-bold;
      margin: 0;
      padding: 0;

      .text-accent {
        color: $color-accent;
        font-weight: $fw-normal;
      }

      @media #{$xs-devices} {
        font-size: 32px;
      }
    }

    .bottom-button {
      margin-top: 60px;
    }
  }

  .shop-bundle-packs-section {
    background-color: $color-bg-dark;

    .bottom-button {
      margin-top: 60px;
    }
  }

  .popular-cards-section {
    .bottom-button {
      margin-top: 60px;
    }
  }

  .gradient-background {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: linear-gradient(94deg,
        rgba(247, 112, 199, 0.25) 0%,
        rgba(251, 139, 231, 0.25) 25%,
        rgba(231, 175, 239, 0.25) 50%,
        rgba(187, 211, 239, 0.25) 75%,
        rgba(139, 231, 235, 0.25) 100%);

    .split-section-header {
      display: flex;
      justify-content: center;

      .text-1 {
        margin: 0;
        font-size: 32px;
        color: $color-accent;
      }

      .text-2 {
        margin: 0;
        font-size: 32px;
        font-weight: $fw-bold;
      }

      &.preferred-brand-section-header {
        @media #{$md-devices} {
          flex-direction: column;
        }
      }

      @media #{$md-devices} {
        justify-content: center;
      }
    }
  }

  .split-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$md-devices} {
      justify-content: center;
    }
  }

  .cards-container-3-per-row {
    display: flex;
    justify-content: center;
    gap: 30px;

    .separator {
      height: auto;
    }
    @media #{$lt-1080-devices} {
      flex-direction: column;
      align-items: stretch;
    }

    @media #{$md-devices} {
    }
  }

  .cards-container-4-per-row {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;

    .icon-container {
      min-height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-text {
        font-size: 30px;
        cursor: pointer;

        &:hover {
          color: $color-accent;
        }
      }

    }

    .gift-card-purchase {
      min-width: 220px;
      max-width: 300px;

      .gift-card {

        .card-text {
          font-size: 13px;
        }
      }

      .purchase-card {

        .card-purchase-name {
          font-size: 16px;
        }

       
      }
    }
  }

  .video-container {
    height: 620px;
    background: linear-gradient(135deg, #ff00ff, #0000ff 50%, #00008b);
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;
    gap: 20px;

    @media #{$lt-1080-devices} {
      justify-content: flex-start;
      padding: 20px 0px;
      height: auto;
      .video {
        width: 80%;
        // max-width: 690px;
        // width: 690px;
        height: 390px;
  
        
      }
    } 

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }

    .video {
      width: 50%;
      height: 390px;
      background-color: black;
      z-index: 2;
      @media #{$lt-1080-devices} {
        width: 80%;
        height: 300px;
      }
      
    }

    .text {
      text-align: center;
      font-size: 64px;
      font-weight: $fw-bold;
      color: #FFFFFF;
      @media #{$lt-1080-devices} {
        font-size: 30px;
        width: 80%;

      }
  
    }

  }


  .get-updates-section {
    .title {
      text-align: center;
      font-size: 48px;
      font-weight: $fw-bold;

      @media #{$lt-1080-devices} {
        font-size: 32px;

      }
    }

    .get-updates-container {
      width: 861px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 30px;

      @media #{$lt-1080-devices} {
        width: auto;

      }

      .app-checkbox-container {

        .app-checkbox-label {
          color: #000000;

          .checkbox-checkmark {
            border-color: #A9A9A9;
          }
        }
      }

      .app-form-element-container {
        max-width: 500px;
      }
    }
  }


  .section-spacing {
    padding: 50px 0 70px 0;
  }

  .section-spacing-small {
    padding: 40px 0 60px 0;
  }

  .dummy-section {
    min-height: 300px;
  }

}