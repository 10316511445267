@import 'src/styles/utils';

.catalogue-side-menu-checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .checkbox-item {
    display: flex;
    align-items: center; 
    gap: 10px; 

    .checkbox {
      display: none;
    }

    .checkbox-label {
      display: flex;
      align-items: center; 
      font-size: 14px;
      color: #000000;
      font-weight: $fw-normal;
      cursor: pointer;

      .checkbox-checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: 1px solid #000000;
        margin-right: 10px; 
        border-radius: 3px;

        .app-icon {
          display: none;
          font-size: 14px;
        }
      }
    }

    .checkbox:checked + .checkbox-label .checkbox-checkmark .app-icon {
      display: inline-block;
      color: $color-accent;
    }
  }
}
