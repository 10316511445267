@import 'src/styles/utils';

$textHeight: 59px;
$totalHeight: 100px;
$textHeightSm: 108px;

.page-title {
  width: 100%;
  color: $color-accent;
  font-size: 26px;
  font-weight: $fw-semi-bold-plus;
  height: $textHeight;
  line-height: $textHeight - 15px;
  margin: ($totalHeight - $textHeight) 0 0 0;
  padding: 0;
  border-bottom: 1px solid $color-accent;

  @media #{$sm-devices} {
    font-size: 32px;
    text-align: center;
    height: $textHeightSm;
    line-height: $textHeightSm;
    margin: 0;
  }
}