/**
 * Direction
 */

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

/**
 * Alignment
 */

.flex-align-start-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-align-start-center {
  justify-content: flex-start;
  align-items: center;
}

.flex-align-start-end {
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-align-center-start {
  justify-content: center;
  align-items: flex-start;
}

.flex-align-center-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center-end {
  justify-content: center;
  align-items: flex-end;
}

.flex-align-end-start {
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-align-end-center {
  justify-content: flex-end;
  align-items: center;
}

.flex-align-end-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-align-space-between-start {
  justify-content: space-between;
  align-items: flex-start;
}

.flex-align-space-between-center {
  justify-content: space-between;
  align-items: center;
}

.flex-align-space-between-end {
  justify-content: space-between;
  align-items: flex-end;
}

/**
 * Size
 */

.flex-1 {
  flex: 1 1 0%;
}

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
}

/**
 * Gap
 */

@mixin flex-gap($gap) {
  gap: $gap;
}

.flex-gap-4 {
  @include flex-gap(4px);
}

.flex-gap-5 {
  @include flex-gap(5px);
}

.flex-gap-7 {
  @include flex-gap(5px);
}

.flex-gap-8 {
  @include flex-gap(5px);
}

.flex-gap-10 {
  @include flex-gap(10px);
}

.flex-gap-15 {
  @include flex-gap(15px);
}

.flex-gap-20 {
  @include flex-gap(20px);
}

.flex-gap-25 {
  @include flex-gap(25px);
}

.flex-gap-30 {
  @include flex-gap(30px);
}

.flex-gap-34 {
  @include flex-gap(34px);
}

.flex-gap-35 {
  @include flex-gap(35px);
}

.flex-gap-40 {
  @include flex-gap(40px);
}

.flex-gap-50 {
  @include flex-gap(50px);
}

.flex-gap-70 {
  @include flex-gap(70px);
}

.flex-gap-100 {
  @include flex-gap(100px);
}

.flex-gap-150 {
  @include flex-gap(150px);
}