@import 'src/styles/utils';


.custom-design-page-content-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @media #{$lt-1080-devices} {
        justify-content: center;
    }
    .custom-design-item {
        padding: 20px;
        max-width: 690px;
        flex: 1 1 100%;
        box-sizing: border-box;
        padding-bottom: 40px;

        &.section1 {
            padding-top: 20px;
            order: 1;

            .gift-card {
                max-width: 570px;
                min-width: 310px;
                max-height: 310px;
                aspect-ratio: 1016/642;
                border-radius: 30px;

                .background-img-container {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .card-text {
                    font-size: 22px;
                }
            }
        }

        &.section2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            order: 2;
            padding-top: 20px;
            color: $color-text-form-field;
            font-weight: $fw-bold;
        }
    }
}