@import 'src/styles/utils';

.app-form-cards-selector-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 696px;


  .app-form-card-option {
    width: 210px;
    height: 127px;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    border: solid 3px transparent;
    box-sizing: border-box;

    &.selected {
      border: solid 3px $color-accent;
    }

    .gift-card {
      min-width: auto;
      max-width: 210px;
      min-height: 127px;

      img {
        max-width: none;
      }
      @media #{$lt-1080-devices} {
        min-width: 170px;
      }
    }
  }
  @media #{$lt-1080-devices} {
    width: auto;
  }

  @media #{$lt-500-devices} {
    justify-content: center;
    align-items: center;
  }
}