@import 'src/styles/utils';

.template-card {
  width: 100%;
  max-width: 450px;
  min-width: 310px;
  aspect-ratio: 450 / 246;
  border-radius: 18px;
  border: 1px solid #000000;
  overflow: hidden;
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;

  &.disabled {
    opacity:  0.5;
  }

  &.selectable {
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }
  }
}