@import 'src/styles/utils';

.app-form-radio-group {

  .radio-group-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .radio-option-container {
      display: flex;
      align-items: center;
      gap: 19px;
      cursor: pointer;

      .radio-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        border: 1px solid $color-accent;

        .radio-checked {
          display: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $color-accent;
        }


        &.checked {
          border: 1px solid $color-accent;

          .radio-checked {
            display: flex;
          }
        }

      }
      .radio-text-container {
        display: flex;
        flex-direction: column;

      
        .radio-text {
          flex: 1 1 0%;
          color: $color-text-form-field;
  
         
        }
  
        .radio-secondary-text {
          color: $color-text-placeholder;
          font-weight: $fw-normal;
        }
      }
    }
  }
}





