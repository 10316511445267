@font-face {
  font-family: 'appIcon';
  src:
    url('fonts/appIcon.ttf?ntk2jz') format('truetype'),
    url('fonts/appIcon.woff?ntk2jz') format('woff'),
    url('fonts/appIcon.svg?ntk2jz#appIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.app-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'appIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-icon-download:before {
  content: "\e91c";
}
.app-icon-3d-cube:before {
  content: "\e919";
}
.app-icon-webpage:before {
  content: "\e91a";
}
.app-icon-favorite:before {
  content: "\e91b";
}
.app-icon-shop:before {
  content: "\e915";
}
.app-icon-pie-chart:before {
  content: "\e916";
}
.app-icon-group:before {
  content: "\e917";
}
.app-icon-credit-card:before {
  content: "\e918";
}
.app-icon-search-plus:before {
  content: "\e90f";
}
.app-icon-trash:before {
  content: "\e914";
}
.app-icon-arrow-right:before {
  content: "\e90e";
}
.app-icon-arrow-up:before {
  content: "\e908";
}
.app-icon-edit:before {
  content: "\e906";
}
.app-icon-add-to-cart:before {
  content: "\e905";
}
.app-icon-wallet:before {
  content: "\e910";
}
.app-icon-shopping-bag:before {
  content: "\e911";
}
.app-icon-bell:before {
  content: "\e912";
}
.app-icon-user:before {
  content: "\e913";
}
.app-icon-check-circle:before {
  content: "\e903";
}
.app-icon-check:before {
  content: "\e901";
}
.app-icon-email:before {
  content: "\e907";
}
.app-icon-menu:before {
  content: "\e909";
}
.app-icon-linkedin:before {
  content: "\e90a";
}
.app-icon-tiktok:before {
  content: "\e90b";
}
.app-icon-instagram:before {
  content: "\e90c";
}
.app-icon-facebook:before {
  content: "\e90d";
}
.app-icon-close:before {
  content: "\e904";
}
.app-icon-search:before {
  content: "\e900";
}
.app-icon-heart:before {
  content: "\e902";
}
.app-icon-arrow-right2:before {
  content: "\ea3c";
}
.app-icon-arrow-left2:before {
  content: "\ea40";
}
