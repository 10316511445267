@import 'src/styles/utils';

.app-dropdown-container {
  position: relative;
  width: 250px;
  font-size: 16px;

  .dropdown-header {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 30px;
    cursor: pointer;
    color: $color-text-placeholder;

    .icon-label-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .dropdown-arrow {
      justify-self: flex-end;
      transition: transform 0.3s;
      transform: rotate(0deg);

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    color: $color-text-placeholder;
    background-color: $color-bg-form-field;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .dropdown-option {
      padding: 10px 35px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #717171;
        border-radius: 30ppx;
      }
    }
  }
}
