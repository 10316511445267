@import 'src/styles/utils';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 50px;
  padding: 0 22px;
  border-radius: 25px;
  overflow: hidden;
  font-size: 16px;
  font-weight: $fw-bold;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &.accent {
    background: $color-accent;
    border: 1px solid $color-accent;
    color: white;

    &:disabled {
      background: $color-accent-disabled;
      border: 1px solid $color-accent-disabled;
    }
  }

  &.outline {
    background: $color-bg-light;
    border: 1px solid $color-accent;
    color: $color-accent;
  }

  &.outline-light {
    background: transparent;
    border: 1px solid $color-text-light;
    color: $color-text-light;
  }
}