@import 'src/styles/utils'; 



.catalogue-side-menu-container {
    .side-menu {
        min-width: 330px;
        background-color: #F4F4F4;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 25px 20px;


        .menu-title {
            font-weight: $fw-normal;
            margin: 0;
        }

        .category-container {
            display: flex;
            gap: 15px;
            flex-direction: column;

            .category-header {
                font-weight: $fw-bold;
            }

            .line-separator {
                background-color: #727272;
            }

        }

    }
}