@import 'src/styles/utils';

.app-form-input {

  .form-field-container {
    input {
      font-size: 16px;
      background: transparent;
      border: none;
      outline: none;
      color: $color-text-form-field;
      width: 100%;

      &::placeholder {
        color: $color-text-placeholder;
      }
    }
  }
}