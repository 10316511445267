@import 'src/styles/utils';

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  .app-icon {
    font-size: 24px;
  }

  &:disabled {
    cursor: default;
  }

  &.accent {
    background: $color-accent;
    border: 1px solid $color-accent;
    color: white;

    &:disabled {
      background: $color-accent-disabled;
      border: 1px solid $color-accent-disabled;
    }
  }

  &.outline {
    background: $color-bg-light;
    border: 1px solid $color-accent;
    color: $color-accent;
  }

  &.outline-light {
    background: transparent;
    border: 1px solid $color-text-light;
    color: $color-text-light;
  }

  &.secondary-accent {
    background: $color-secondary-accent;
    border: 1px solid $color-secondary-accent;
    color: white;
  }
}