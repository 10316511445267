@import 'src/styles/utils';

.custom-design-info-panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .custom-design-info-panel {
    border: 1px solid #717171;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    gap: 30px;
    box-sizing: border-box;
   

    .info-panel-tabs {
      max-width: 570px;
      display: flex;

      .button-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-bottom: 1px solid #000000;
        padding-bottom: 10px;

        &:nth-child(1) {
          padding-right: 40px;
          padding-left: 0;
        }

        &:nth-child(2) {
          padding-right: 40px;
          padding-left: 40px;
        }

        &:nth-child(3) {
          padding-right: 0;
          padding-left: 40px;
        }

        &.active {
          border-bottom: 3px solid $color-accent;

          button {
            color: $color-accent;
          }
        }
      }

      button {
        color: #717171;
        position: relative;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: $fw-semi-bold;
        padding: 10px 0;
      }
    }

    .content {
      padding-top: 16px;
      color: #979797;
      font-weight: $fw-semi-bold;
      letter-spacing: 0.04em;
      line-height: 1.8;
      margin-top: 20px; 

      .title {
        font-weight: $fw-bold;
        color: black
      }

      .text {
        margin-bottom: 20px;
      }
    }
  }

  @media #{$lt-1080-devices} {
    .custom-design-info-panel {
      width: 100%; 
      padding: 20px 30px;

      .info-panel-tabs {
        flex-direction: column;
        justify-content: flex-start; 

        .button-container {
          padding-right: 0 !important;
          padding-left: 0 !important;
          margin-bottom: 0;
          border-bottom: 1px solid #000000;
          flex-direction: column;

          &:last-child {
            border-bottom: none; 
          }
          &.active {
            border: none;
            button {
              color: $color-accent;
              border-bottom: 3px solid #F15D60;
            }
           
          }

          button {
            text-align: left;
            width: 100%;
          }

          .content {
            margin-top: 10px; 
          }
        }
      }

      .content {
        margin-top: 10px; 
      }
    }
  }
}
