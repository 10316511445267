@import './custom-design-page.scss';

.custom-design-item {
  &.section2 {
    .app-upload-container {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      flex: 1 1;
      margin-top: 10px;
      padding: 5px 6px;

      .image-button {
        width: 50%;
      }

      .upload-text {
        width: 50%;
        padding-left: 20px;
        font-weight: $fw-normal;
        font-size: 14px;
        color: #A2A2A2;

        .bolded-text {
          font-weight: $fw-bold;
        }
      }
    }

    .merchant-selector-container {
      .app-form-element-container {
        max-width: none;
        .app-dropdown-container {
          width: 100%;
        }
      }
    }

    .separator-color {
      background-color: $color-text-placeholder;
    }

    .templates-icons-container {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        flex: 1;
      }

      .app-dropdown-container {
        width: 330px;
      }

      @media #{$lt-1080-devices} {
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;

        .app-dropdown-container {
          width: 100%;
        }
      }
    }

    .app-form-radio-group {
      .radio-group-container {
        gap: 20px;
        width: 494px;
        flex-wrap: wrap;

        .radio-option-container {
          cursor: pointer;

          .radio-container {
            min-width: 24px;
            min-height: 24px;
            border-radius: 50%;

            .radio-checked {
              width: 16px;
              height: 16px;
              border-radius: 50%;
            }

            @media #{$lt-1080-devices} {
              min-width: none;
            }

          }

          .radio-text-container {
            .radio-text {
              color: $color-bg-dark;
            }

            .radio-secondary-text {
              color: $color-text-placeholder;
              font-weight: $fw-normal;
            }
          }
        }

        @media #{$lt-1080-devices} {
          width: 100%;
          gap: 20px !important;
        }
      }
    }

    .button-container {
      display: flex;
      margin-top: 30px;
      justify-content: flex-end;
      align-items: flex-end;
      @media #{$lt-1080-devices} {
        justify-content: center;
      }
    }
  }
}