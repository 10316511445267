@import 'src/styles/utils';

.app-upload-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 6px;

  .upload-button {
    width: 50%;
    font-size: 16px;
    font-weight: $fw-bold;

    @media #{$lt-1080-devices} {
      width: 100%;

    }
  }

  .upload-text {
    width: 100%;
    padding-top: 10px;
    font-weight: $fw-normal;
    font-size: 14px;
    color: #A2A2A2;

    .bolded-text {
      font-weight: $fw-bold;
    }

    @media #{$lt-1080-devices} {
      width: 100% !important;
     
    }
  }

  @media #{$lt-1080-devices} {
    flex-direction: column;
  }

}

.app-file-info {
  width: 100%;
  display: flex;
  align-items: center;

  .file-name {
    font-weight: $fw-bold;
    font-size: 14px;
    color: #333;
    margin-right: 10px;
  }

  .remove-file-button {
    cursor: pointer;
    font-size: 12px;
    color: $color-accent;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    margin-left: 10px;

    &:hover {
      color: #ff7875;
    }
  }
}