@import 'src/styles/utils';

.app-form-readonly {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .label {
    font-size: 16px;
    font-weight: $fw-bold;
    color: $color-text-dark;
  }

  .value {
    font-size: 16px;
    font-weight: $fw-semi-bold;
    color: $color-text-disabled-secondary;
  }
}