@import 'src/styles/utils';

.my-account-layout .page-content {
  gap: 40px;

  .account-router {
    display: flex;
    align-items: stretch;
    width: 100%;
    border-radius: 20px;

    .account-menu {
      width: 360px;
      padding: 0px 12px 45px 45px;
      box-sizing: border-box;
      border-radius: 20px 0 0 20px;
      // box-shadow: 5px 0 5px 0 $color-shadow;
      display: flex;
      flex-direction: column;
      min-height: 500px;
    }

    .account-page {
      flex: 1 1 0%;
      padding: 8px 40px 65px 40px;
    }
  }
}