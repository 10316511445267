@import 'src/styles/utils';


.app-form-color-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .color-option {
        width: 55px;
        height: 55px;
        cursor: pointer;
        border: 3px solid transparent;
        display: inline-block;

        &.selected {
            border-color: $color-accent;
        }
        @media #{$lt-1080-devices} {
            width: 60px;
            height: 60px;
        }
    }
    @media #{$lt-1080-devices} {
        justify-content: center;
        .color-option {
        width: 60px;
        height: 60px;
    }
    }
}



