@import '../../../../styles/utils.scss';

.checkout-group-user-section {

    .header {
        font-weight: $fw-semi-bold;
        color: $color-text-placeholder;
    }

    .header-separator {
        height: 1px;
        width: 100%;
        background-color: $color-separator;
    }

    .user {
        min-width: 50%;
    }

    .amount {
        width: 50%;
        display: flex;

    }

    .users-section {
        max-height: 320px;
        overflow-y: auto;


        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            width: 2px;
            background-color: #ffffff;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-accent;
            border-radius: 10px;
            width: 10px;

        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #00D6B6;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }


        .user-order-details {
            box-sizing: border-box;
            padding-right: 5px;
            .user-details {
                .full-name {
                    color: #000000;
                    font-weight: $fw-bold;
                }

                .email {
                    color: $color-text-placeholder;
                    font-weight: $fw-semi-bold;

                    span {
                        color: #000000;
                    }
                }


                .phone {
                    font-weight: $fw-semi-bold;
                    color: $color-text-placeholder;

                    span {
                        color: #000000;
                    }
                }
            }


            .status {

                &.pending {
                    color: #F6A509;
                }

                &.paid {
                    color: #00BF2A;
                }
            }

            .amount-time {
                font-weight: $fw-bold;

                .amount-value {
                    color: #000000;
                }

                .time-left {
                    font-size: 12px;
                    color: #F6A509;
                }
            }

            .separator {
                height: 1px;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.10);
            }
        }
    }
}