@import 'src/styles/utils';

.switch-toggle {
  display: flex;
  align-items: stretch;
  height: 60px;
  width: 100%;
  max-width: 690px;
  border-radius: 30px;
  background-color: $color-bg-disabled;
  padding: 5px 6px;
  box-sizing: border-box;

  .switch-toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 30px;
    background-color: transparent;
    color: $color-text-disabled;
    font-size: 16px;
    font-weight: $fw-bold;
    flex: 1 1 0%;
    cursor: pointer;

    &.active-button {
      background-color: $color-accent;
      color: $color-text-light;
      font-weight: $fw-bold;
      cursor: default;

      .app-icon {
        color: $color-text-light;
        font-size: 20px;
      }
    }
  }

  @media #{$sm-devices} {
    height: 50px;
    
    .switch-toggle-button {
      font-size: 12px;
      padding-left: 5px;

      &.active-button {
        font-size: 12px;
        .app-icon {
          font-size: 12px;
        }
      }
    }
  }
}