@import 'src/styles/utils';

.cards-table {
  .t-body {
    .card-row {
      &:not(:last-child) {
        border-bottom: 1px solid $color-separator;
      }
    }
  }

  .t-row .t-cell {
    text-align: left;

    &.cell-name {
      flex: 0 1 30%;
    }

    &.cell-value {
      flex: 0 1 20%;
      text-align: right;
      padding-right: 20px;
    }

    &.cell-sales-number {
      flex: 0 1 20%;
    }

    &.cell-expire-date {
      flex: 0 1 20%;
    }

    &.cell-actions {
      flex: 0 1 10%;
    }
  }

  @media #{$sm-devices} {
    .t-row .t-cell {
      &.cell-name, &.cell-value {
        flex: 0 1 40%;
      }

      &.cell-actions {
        flex: 0 1 20%;
      }
    }
  }
}