@import 'src/styles/utils';

.app-input {
  input {
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    color: $color-text-form-field;
    width: 100%;

    &::placeholder {
      color: $color-text-placeholder;
    }
  }
  button {
    margin: 5px 0px;
    &.button {
      font-weight: $fw-normal;
    }
  }

}