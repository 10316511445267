$content-max-width: 1430px;

$color-bg-dark: #000000;
$color-bg-light: #FFFFFF;
$color-bg-neutral: #F6F6F6;

$color-text-dark: #000000;
$color-text-light: #FFFFFF;
$color-accent: #F15D60;
$color-secondary-accent: #25A9F2;
$color-accent-disabled: #F8AEAF;
$color-success: #00BA00;
$color-error: #F44336;
// disabled/inactive elements
$color-bg-disabled: #F4F4F4;
$color-text-disabled: #717171;
$color-text-disabled-secondary: #979797;
$color-shadow: #E5E5E5;
$color-separator: #979797;
$color-separator-secondary: #E4E4E4;
$color-text-disabled-light: #C7C7C7;

/**
 * Spacing
 */
$layout-side-padding: 25px;
$layout-side-padding-sm: 15px;
$layout-side-padding-xxs: 5px;
//$page-padding-h: 16px;

/**
 * Fonts
 */
$fw-normal: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-semi-bold-plus: 750;


/**
 * Form elements
 */
$form-element-max-width: 450px;
$color-bg-form-field: #F4F4F4;
$color-text-form-field: #717171;
$color-text-placeholder: #979797;

/**
 * Media query
 */
$sm-min-size: 600px;
$md-min-size: 960px;
$lg-min-size: 1280px;
$xl-min-size: 1920px;

$lt-1080-devices: "only screen and (max-width: 1079px)";
$lt-800-devices: "only screen and (max-width: 799px)";
$lt-500-devices: "only screen and (max-width: 499px)";
$lt-400-devices: "only screen and (max-width: 399px)";
$lt-375-devices: "only screen and (max-width: 374px)";
$xs-devices: "only screen and (max-width: #{$sm-min-size - 1})";
$gt-xs-devices: "only screen and (min-width: #{$sm-min-size})";
$sm-devices: "only screen and (max-width: #{$md-min-size - 1})";
$gt-sm-devices: "only screen and (min-width: #{$md-min-size})";
$md-devices: "only screen and (max-width: #{$lg-min-size - 1})";
$gt-md-devices: "only screen and (min-width: #{$lg-min-size})";
$lg-devices: "only screen and (max-width: #{$xl-min-size - 1})";
$gt-lg-devices: "only screen and (min-width: #{$xl-min-size})";
