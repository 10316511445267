@import 'src/styles/utils';

.admin-layout {
  .page-content {
    gap: 40px;

    .admin-router {
      display: flex;
      align-items: stretch;
      width: 100%;
      border-radius: 20px;

      .admin-page {
        flex: 1 1 0%;
        padding: 8px 40px 65px 40px;

        .tab-card-container {
          border-radius: 20px;
          border: 1px solid $color-separator-secondary;
          background: #FFF;
          overflow: hidden;
        }
      }
    }
  }

  /* TABLET/MOBILE STYLE */
  @media #{$lt-1080-devices} {
    .page-content {
      gap: 20px;
      .admin-router {
        display: block;
        padding: 0;

        .admin-page {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}