@import 'src/styles/utils';

.app-form-element-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  max-width: $form-element-max-width;

  .form-field-container {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 21px;
    border-radius: 30px;
    background-color: $color-bg-form-field;
    display: flex;
    align-items: center;
  }

  .form-element-error {
    color: $color-error;
    font-size: 13px;
    height: 15px;
    line-height: 15px;
  }
}