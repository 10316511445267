/**
 * Entry point for all styles
 */

/**
 * Load external libs
 */
// icons
@import '../assets/appIcon/style.scss';
.app-icon {
  // default icon size
  font-size: 32px;
}
// react-date-picker
@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';

/**
 * Load helper styles (classes, ids etc.)
 */
@import 'flexbox';
@import 'util-classes';

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.display-block {
  display: block;
}
