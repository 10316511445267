@import 'src/styles/utils';


.home-page-part-invite-friends-container {
    min-width: 100%;
    padding: 0 100px;
    justify-content: center;
    background: rgb(100,171,253);
    background: linear-gradient(94deg, 
        #64abfd 0%, 
        #676aed 25%, 
        #2b1278 50%, 
        #c037f6 75%, 
        #bc32fa 100%);

    .home-page-part-invite-friends {
        display: flex;
        gap: 50px;
        padding: 50px 40px;

        @media #{$lt-1080-devices} {
            flex-direction: column;
            padding: 50px 0px;
            justify-content: center;
            align-items: center;
          }
          
      
          @media #{$md-devices} {
          }

        .app-form-section-title {
            padding: 0;

            .title {
                h2 {
                    color: #F15D60;
                    font-weight: $fw-bold;
                    @media #{$lt-1080-devices} {
                        color: #FFFFFF;
                      }
                      
                }
            }

            p {
                color: #FFFFFF;
                font-weight: $fw-normal;
                &.info {
                    @media #{$lt-1080-devices} {
                        display:inline
                      }
                }
            }
        }

        .form-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .form-inputs-container {
                display: flex;
                gap: 20px;

                @media #{$lt-1080-devices} {
                    flex-direction: column;

                    button {
                        align-self: center;
                        width: 100%;
                        background-color: #000000;
                        border-color: transparent;
                    }
                  }
              
                  @media #{$md-devices} {
                  }

                .form-email {
                    width: 330px;

                    .app-form-element-label {
                        color: #FFFFFF;
                        font-weight: $fw-normal;
                    }

                    .app-form-element-container .form-field-container {
                        background-color: #FFFFFF;
                    }
                }

                .form-phone-number-container {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;



                    .phone-number-label {
                        color: #FFFFFF;
                        font-weight: $fw-normal;
                    }

                    .form-prefix {
                        padding-top: 11px;
                        width: 130px;

                        .app-dropdown-container {
                            .dropdown-header {
                                background-color: #FFFFFF;
                                font-weight: $fw-normal;
                            }
                        }
                    }

                    .form-phone-number {
                        width: 220px;

                        .app-form-element-container .form-field-container {
                            background-color: #FFFFFF;
                        }
                    }
                }
            }

        }

        .button {
            align-self: flex-end;
            font-weight: $fw-normal;
        }

        .link-container {
            display: flex;
            gap: 40px;
            align-items: center;

            @media #{$lt-1080-devices} {
                flex-direction: column;
                justify-content: center;
                align-items: center;

              }

            .link-text {
                font-size: 24px;
                color: #A0A0A0;
                font-weight: $fw-normal;

                @media #{$lt-1080-devices} {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #FFFFFF;
                    font-size: 20px;

                    .link {
                        color: #FFFFFF;
                        font-size: 20px;
                    }
                  }
            }

            .link {
                color: #000000;
                font-weight: $fw-bold;
            }

            .copy-share {

                color: #F15D60;
                font-weight: $fw-normal ;
                cursor: pointer;

                @media #{$lt-1080-devices} {
                    color: #FFFFFF;
                    font-size: 24px;

                   
                  }
            }
        }
    }
}