@import 'variables';

@mixin page-side-padding {
  padding-left: $layout-side-padding;
  padding-right: $layout-side-padding;

  @media #{$xs-devices} {
    padding-left: $layout-side-padding-sm;
    padding-right: $layout-side-padding-sm;
  }

  @media #{$lt-375-devices} {
    padding-left: $layout-side-padding-xxs;
    padding-right: $layout-side-padding-xxs;
  }
}