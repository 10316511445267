@import 'src/styles/utils';

.dashboard-form-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .dashboard-form-section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid $color-separator;
    margin-bottom: 30px;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-weight: $fw-bold;
    }

    .action-button {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: $fw-semi-bold;
      color: $color-accent;
      cursor: pointer;

      .app-icon {
        color: $color-accent;
        font-size: 18px;
      }

      &.disabled {
        color: $color-text-disabled;
        cursor: default;

        .app-icon {
          color: $color-text-disabled;
        }
      }
    }
  }

  .dashboard-form-section-content {
    .form-label {
      font-size: 16px;
      font-weight: $fw-bold;
      color: $color-text-dark;
    }
  }
}