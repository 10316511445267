@import 'src/styles/utils';

.merchants-page {
  padding: 30px 0;
  border-radius: 20px;
  border: 1px solid $color-separator-secondary;
  background: #FFF;

  .subtitle-bicolor {
    padding-left: 30px;
  }
}