@import 'src/styles/utils';

.card-details-readonly {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .card-holder {
    font-size: 16px;
    font-weight: $fw-semi-bold;
    color: $color-text-disabled-secondary;

    .label {
      font-weight: $fw-bold;
      color: $color-text-dark;
    }
  }

  .card-priority {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: $fw-bold;
    color: #BFBFBF;

    &.primary {
      color: $color-accent;
    }
  }

  .card-info {
    .card-icon {
      width: 48px;
    }
    .label {
      font-size: 16px;
      font-weight: $fw-bold;
      color: $color-text-dark;
    }
    .value {
      font-size: 16px;
      font-weight: $fw-semi-bold;
      color: $color-text-disabled-secondary;
    }
  }
}