@import 'src/styles/utils';

.gift-card-purchase {
  width: 100%;
  max-width: 450px;
  min-width: 310px;
  container: gift-card / inline-size;

  .purchase-card {
    .card-purchase-name {
      font-size: 20px;
      font-weight: $fw-bold;
      color: $color-accent;
    }
    .card-purchase-price {
      font-size: 18px;
      font-weight: $fw-bold;
    }
    .button-icon {
      display: none;
      margin-right: 20px;
    }
  }

  @container gift-card (inline-size < 450px) {
    .purchase-card {
      .button {
        // display: none;
      }
      .button-icon {
        display: flex;
      }
    }
  }
}