@import 'src/styles/utils';

.home-page-part-give-a-gift {
  width: 100%;
  display: flex;
  flex-direction: column;

  .box-card {
    height: 550px;
    background-color: $color-bg-dark;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
  }

  .design {
    flex: 1 1 0%;
    padding-left: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90%;
    }
  }

  .text {
    padding: 75px 70px 75px 0;
    color: $color-text-light;
    max-width: 490px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 96px;
      font-weight: $fw-bold;
      line-height: 1;
    }
    .subtitle {
      font-size: 48px;
      font-weight: $fw-normal;
      color: $color-accent;
      line-height: 1;
      padding-left: 5px;
    }
    .helper {
      font-size: 16px;
      font-weight: $fw-bold;
      padding-left: 5px;
      margin: 10px 0;
    }
    .info {
      font-size: 16px;
      font-weight: $fw-normal;
      line-height: 1.5;
      padding-left: 5px;
      margin-bottom: 30px;
    }
  }

  @media #{$md-devices} {
    .box-card {
      height: 450px;
    }

    .text {
      padding: 75px 50px 75px 0;
      max-width: 400px;

      .title {
        font-size: 60px;
      }
      .subtitle {
        font-size: 35px;
      }
    }
  }

  @media #{$sm-devices} {
    align-items: center;

    .box-card {
      flex-direction: column-reverse;
      height: unset;
      max-width: 500px;
      padding: 40px 20px;
      box-sizing: border-box;
      gap: 30px;
    }

    .design {
      padding: 0;

      img {
        width: 100%;
      }
    }

    .text {
      padding: 0;
    }
  }

  @media #{$xs-devices} {
    .text {
      .title {
        font-size: 50px;
        line-height: 1.5;
      }
      .subtitle {
        font-size: 28px;
      }
    }
  }
}