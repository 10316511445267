@import 'src/styles/utils';

.subtitle-bicolor {
  display: flex;
  font-size: 24px;
  font-weight: $fw-normal;
  color: $color-accent;
  margin: 0;
  padding: 0;

  .text-bolded {
    color: $color-text-dark;
    font-weight: $fw-bold;

    &.light-theme {
      font-weight: $fw-normal;
      color: $color-accent;
    }
  }

  &.light-theme {
    color: $color-text-light;
    font-weight: $fw-bold;
  }

  &.is-header {
    font-size: 48px;

    @media #{$xs-devices} {
      font-size: 32px;
    }
  }
}