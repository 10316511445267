@import 'src/styles/utils';

.home-page-part-brands-container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .brand-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 25px;
        justify-content: center;
        align-items: center;
    }

    button {
        &.button.outline {
        background: transparent;
    }
    }

    img {
        mix-blend-mode: multiply;
    }

   
}