@import 'src/styles/utils';

.app-checkbox-container {
  display: flex;
  align-items: center;

  .app-checkbox {
    display: none;
  }

  .app-checkbox-label {
    position: relative;
    padding-left: 50px;
    font-size: 16px;
    color: $color-text-form-field;
    font-weight: $fw-normal;

    .checkbox-checkmark {
      position: absolute;
      left: 0;
      top: 0;
      height: 31px;
      width: 31px;
      background-color: transparent;
      border: 2px solid #727272;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .app-icon {
        display: none;
        font-size: 22px;
      }
    }
  }

  .app-checkbox:checked + .app-checkbox-label .checkbox-checkmark {
    .app-icon {
      display: inline-block;
      color: $color-accent;
      cursor: pointer;
    }
  }
}
