@import 'src/styles/utils';


.account-created-page-container {

    .account-created-content {
        padding: 100px 0;
        width: 450px;

        .button {
            max-width: 210px;
        }

        .account-text-icon-container {
            padding: 0px 50px;

        .account-created-primary-text {
            font-size: 24px;
            font-weight: $fw-bold;
            text-align: center;
        }


        .account-created-secondary-text {
            text-align: center;
            color: #979797;
        }
    }

        .icon-placeholder {
            width: 120px;
            height: 120px;
            border: 3px solid #04C200;
        }
        @media #{$lt-1080-devices} {
            padding: 100px 40px;
            
        }
    }
}