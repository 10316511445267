@import 'src/styles/utils';

.gift-card {
  position: relative;
  width: 100%;
  max-width: 450px;
  min-width: 310px;
  aspect-ratio: 450 / 246;
  border-radius: 18px;
  overflow: hidden;
  padding: 0;
  color: $color-text-light;

  &.theme-dark {
    color: $color-text-light;
  }
  &.theme-light {
    color: $color-text-dark;
  }

  &.upload-on {
    border: 3px dashed black;

    @media #{$lt-1080-devices} {
      border: 1px dashed black;
    }
  }

  .drag-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    font-weight: $fw-normal;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
    color: $color-bg-dark;
    z-index: 1;

    @media #{$lt-1080-devices} {
      display: none;
    }
  }

  .card-layer-container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 5.54% 6.2%;
    box-sizing: border-box;
  }

  .background-img-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 13px;

    img {
      width: 29%;
      height: auto;
    }

    .cip-image {
      width: 20%;
    }

    .mastercard-logo-hidden {
      width: 17%;
      height: auto;
      visibility: hidden;
    }

    .qr-image {
      width: '12.19%';
      height: auto;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    img {
      width: 17%;
      height: auto;
    }
    .qr-image {
      width: 13.19%;
      resize: 'contain',
    }

    .bloom-logo-hidden {
      width: 29%;
      height: auto;
      visibility: hidden;
    }
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-weight: $fw-semi-bold;
    font-size: 18px;
  }
  .icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}