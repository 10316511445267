@import 'src/styles/utils';

.content-box {
  width: auto;
  min-width: 690px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 3px 3px #dcdcdc;
  padding: 35px 60px;

  @media #{$sm-devices} {
    width: 100%;
    min-width: unset;
    border-radius: unset;
    box-shadow: unset;
    padding: 0;
  }
}