@import '../../styles/utils';

.merchants-table {
  .t-row .t-cell {
    &.cell-merchant, &.cell-date, &.cell-manager, &.cell-actions {
      flex: 0 1 18%;
    }

    &.cell-shops, &.cell-users, &.cell-templates {
      flex: 0 1 10%;
      text-align: center;
    }
  }

  @media #{$sm-devices} {
    .t-row .t-cell {
      &.cell-merchant, &.cell-manager {
        flex: 0 1 30%;
      }

      &.cell-actions, &.cell-date {
        flex: 0 1 20%;
      }
    }

    .more-details {
      .manager, .date-added {
        display: none;
      }
    }
  }

  @media #{$xs-devices} {
    .t-row .t-cell {
      &.cell-merchant, &.cell-actions {
        flex: 0 1 50%;
      }
    }

    .more-details {
      .manager, .date-added {
        display: block;
      }
    }
  }
}